/* Fonts */
////@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700);
////@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
////@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=all);
// Variables (default)
@import "variables";
// Bootstrap (default)
///@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../node_modules/bootstrap-sweetalert/dist/sweetalert.css";
// BEGIN LAYOUT FIRST STYLES (Pefstra layout)
// BEGIN GLOBAL MANDATORY STYLES (Pefstra layout)
/* icons */
@import "../../unmanaged_packages/font-awesome/css/font-awesome.min.css";
@import "../../unmanaged_packages/simple-line-icons/simple-line-icons.min.css";
/* end icons */
///@import "../../../unmanaged_packages/bootstrap/css/bootstrap.css";
// BEGIN PLUGINS STYLES (Pefstra layout)
////@import url(https://cdn.datatables.net/v/bs/jszip-3.1.3/pdfmake-0.1.27/dt-1.10.15/af-2.2.0/b-1.3.1/b-colvis-1.3.1/b-flash-1.3.1/b-html5-1.3.1/b-print-1.3.1/cr-1.3.3/fc-3.2.2/fh-3.1.2/kt-2.2.1/r-2.1.1/rg-1.0.0/rr-1.2.0/sc-1.4.2/se-1.2.2/datatables.min.css);
////@import url(https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.2/css/bootstrap-select.min.css);
///@import "../../../unmanaged_packages/jquery-nestable/jquery.nestable.css";
///@import url(//cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css);
///@import "../../../unmanaged_packages/bootstrap-sweetalert/sweetalert.css";
///@import "../../../unmanaged_packages/bootstrap-tagsinput/bootstrap-tagsinput.css";
///@import "../../../unmanaged_packages/bootstrap-timepicker/css/bootstrap-timepicker.min.css";
///@import "../../../unmanaged_packages/ladda-bootstrap/ladda-themeless.min.css";
// BEGIN THEME GLOBAL STYLES -->
@import "../css/components-md.css";
@import "../css/plugins-md.css";
@import "../../node_modules/select2/dist/css/select2.css";
//@import "../../../node_modules/select2-bootstrap-css/select2-bootstrap.css";
// BEGIN THEME LAYOUT STYLES -->
@import "../css/layout.css";
@import "../css/custom.css";
@import "table";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

///@import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.css";
//@import "../../../node_modules/datatables.net-bs/css/dataTables.bootstrap.css";
